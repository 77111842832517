// componente para renderizar a imagem de acesso negado
// importando o React
import React from 'react';
// importando o componente de card
import MainCard from './MainCard';
// importando o componente de imagem
import forbidden from '../../assets/images/forbidden.svg';
// tostify com mensagem de sem acesso
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// criando o componente
const Forbidden = () => {
    // exibindo a mensagem de sem acesso
    toast.error('Acesso negado', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return (
        <>
            <ToastContainer rtl />
            <MainCard content={false}>
                <img src={forbidden} alt="Acesso negado" />
            </MainCard>
        </>
    );
};

export default Forbidden;