import React, { Suspense } from "react";
import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// project imports
import NavigationScroll from "./utils/NavigationScroll";
import MainRoute from "./routes/index";
// themes
import themes from "./themes";
import "react-big-calendar/lib/css/react-big-calendar.css";

function App() {
    const customization = useSelector((state) => state.Layout);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline>
                    <NavigationScroll>
                        <Suspense fallback={<h1>Loading Components...</h1>}>
                            <MainRoute />
                        </Suspense>
                    </NavigationScroll>
                </CssBaseline>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
