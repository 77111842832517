// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `#ffffff`,
	"primaryLight": `#ffffff`,
	"primary200": `#cbe3f7`,
	"primaryMain": `#8ECAE6`,
	"primaryDark": `#FE5200`,
	"primary800": `#1565c0`,
	"onGoing": `#F3C600`,
	"delayed": `#EA4A34`,
	"coming": `#2F5597`,
	"sent": `#20CE6D`,
	"sentDelayed": `#2C97DE`,
	"total": `#8F3FAF`,
	"secondaryLight": `#f5f5f5`,
	"secondary200": `#F58300`,
	"secondaryMain": `#b39ddb`,
	"secondaryDark": `#FE5200`,
	"secondary800": `#F66E3C`,
	"secondaryPurple": `#541690`,
	"primaryButtonLight": `#ffffff`,
	"primaryButton200": `#B2A573`,
	"primaryButtonMain": `#90FE13`,
	"primaryButtonDark": `#8AD303`,
	"primaryButton800": `#67787F`,
	"secondaryButtonLight": `#f5f5f5`,
	"secondaryButton200": `#E1016D`,
	"secondaryButtonMain": `#b39ddb`,
	"secondaryButtonDark": `#E40755`,
	"secondaryButton800": `#F66E3C`,
	"successLight": `#b9f6ca`,
	"success200": `#69f0ae`,
	"successMain": `#00e676`,
	"successDark": `#00c853`,
	"errorLight": `#ef9a9a`,
	"errorMain": `#f44336`,
	"errorDark": `#c62828`,
	"orangeLight": `#fbe9e7`,
	"orangeMain": `#ffab91`,
	"orangeDark": `#d84315`,
	"warningLight": `#fff8e1`,
	"warningMain": `#ffe57f`,
	"warningDark": `#ffc107`,
	"grey50": `#fafafa`,
	"grey100": `#f5f5f5`,
	"grey200": `#eeeeee`,
	"grey300": `#e0e0e0`,
	"grey500": `#9e9e9e`,
	"grey600": `#757575`,
	"grey700": `#616161`,
	"grey900": `#023047`,
	"darkPaper": `#111936`,
	"darkBackground": `#1a223f`,
	"darkLevel1": `#023047`,
	"darkLevel2": `#212946`,
	"darkTextTitle": `#d7dcec`,
	"darkTextPrimary": `#bdc8f0`,
	"darkTextSecondary": `#8492c4`,
	"darkPrimaryLight": `#e3f2fd`,
	"darkPrimaryMain": `#2196f3`,
	"darkPrimaryDark": `#1e88e5`,
	"darkPrimary200": `#90caf9`,
	"darkPrimary800": `#1565c0`,
	"darkSecondaryLight": `#d1c4e9`,
	"darkSecondaryMain": `#7c4dff`,
	"darkSecondaryDark": `#651fff`,
	"darkSecondary200": `#b39ddb`,
	"darkSecondary800": `#6200ea`
};
export default ___CSS_LOADER_EXPORT___;
