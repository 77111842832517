import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    selectedDistribuidora: null,//"CEB",
    selectedYear: new Date().getFullYear(),
    selectedComponente: null,
    selectedCenario: null,
    paginationModel: { pageSize: 10, page: 0, totalPages: 1 },
  },
  reducers: {
    setSelectedDistribuidora: (state, action) => {
      state.distribuidora = action.payload;
    },
    setSelectedYear: (state, action) => {
      state.year = action.payload;
    },
    setSelectedComponente: (state, action) => {
      state.componente = action.payload;
    },
    setSelectedCenario: (state, action) => {
      state.cenario = action.payload;
    },
    setPaginationModel: (state, action) => {
      const { pageSize, page, totalPages } = action.payload;
      state.paginationModel.pageSize = pageSize;
      state.paginationModel.page = page;
      state.paginationModel.totalPages = totalPages;
    },
  },
});

export const {
  setSelectedDistribuidora,
  setSelectedYear,
  setSelectedComponente,
  setSelectedCenario,
  setPaginationModel,
} = filterSlice.actions;

export default filterSlice.reducer;
