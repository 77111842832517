import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 18000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

export default axiosInstance;