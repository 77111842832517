import React, { forwardRef } from "react";

import {
    Card,
    CardContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import config from "../../config";


const MainCardV2 = forwardRef(
    ({
        border = true,
        boxShadow,
        children,
        content = true,
        contentClass = "",
        contentSX = {},
        shadow,
        sx = {},
        exp = false,
        ...others
    },
        ref
    ) => {
        const theme = useTheme();
        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: `2px solid ${theme.palette.primary.dark}`,
                    boxShadow: "rgba(149, 157, 165, 0.1) 0px 8px 24px",
                    borderRadius: config.borderRadius,
                    ...sx,
                }}
            >
                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

export default MainCardV2;
