import React, { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Project imports
import MinimalLayout from "../pages/Login/MinimalLayout";
import config from "../config";
import Loadable from "../components/Loader/Loadable";
import Forbidden from "../components/Card/Forbidden";
import { validateToken } from "../reducers/Login";

// MAIN PAGES
const InfluenciadorMainLayout = Loadable(
  lazy(() => import("../layout/influenciador"))
);
const AntenadoMainLayout = Loadable(lazy(() => import("../layout/antenado")));
const InteressadoMainLayout = Loadable(
  lazy(() => import("../layout/interessado"))
);
const Tornado = Loadable(
  lazy(() => import("../pages/Influenciador/Graphics/Tornado"))
);
const Cone = Loadable(
  lazy(() => import("../pages/Influenciador/Graphics/Cone"))
);
const Projecoes = Loadable(lazy(() => import("../pages/Projecoes/index")));
const Premissas = Loadable(lazy(() => import("../pages/Projecoes/Premissas")));
const PremissasDistribuidoras = Loadable(
  lazy(() => import("../pages/Projecoes/PremissasDistribuidoras"))
);
const Tarifas = Loadable(lazy(() => import("../pages/Tarifas/index")));
const Encargos = Loadable(lazy(() => import("../pages/Custos/Encargos")));

// AUTH PAGES
const Login = Loadable(lazy(() => import("../pages/Login")));
//const is_antenado = localStorage.getItem('is_antenado');

export default function Routes() {
  let isAuthenticated = useSelector((state) => state.Login.isAuthenticated);
  let isForbidden = useSelector((state) => state.Login.isForbidden);
  let profile = useSelector((state) => state.Login.profile);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(validateToken());
  }, [dispatch, isAuthenticated]);

  const AuthRoutes = {
    path: "/",
    element: !isAuthenticated ? (
      <MinimalLayout />
    ) : (
      <Navigate to="/influenciador/projecoes" />
    ),
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/",
        element: <Login />,
      },
    ],
  };

  const MainRoutes = {
    path: "/influenciador",
    element: isAuthenticated ? (
      // renderizar o layout de acordo com o perfil do usuário. Perfis: Influenciador, Antenado, Interessado
      profile.some((data) =>
        ["Influenciador", "Sys.Admin"].includes(data.name)
      ) ? (
        <InfluenciadorMainLayout />
      ) : profile.some((data) => ["Antenado"].includes(data.name)) ? (
        <AntenadoMainLayout />
      ) : (
        <InteressadoMainLayout />
      )
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      {
        path: "/influenciador/tornado/b1",
        element: <Tornado default_modalidade="B1" />,
      },
      {
        path: "/influenciador/tornado/energia-demanda",
        element: <Tornado />,
      },
      {
        path: "/influenciador/cone/energia-demanda",
        element: <Cone />,
      },
      {
        path: "/influenciador/cone/b1",
        element: <Cone default_modalidade="B1" />,
      },
      {
        path: "/influenciador/projecoes",
        element: <Projecoes />,
      },
      {
        path: "/influenciador/projecoes/premissas",
        element: isForbidden ? <Forbidden /> : <Premissas />,
      },
      {
        path: "/influenciador/projecoes/premissas-distribuidoras",
        element: isForbidden ? <Forbidden /> : <PremissasDistribuidoras />,
      },
      {
        path: "/influenciador/tarifas",
        element: isForbidden ? <Forbidden /> : <Tarifas />,
      },
      {
        path: "/influenciador/custos/encargos",
        element: isForbidden ? <Forbidden /> : <Encargos />,
      },
    ],
  };

  return useRoutes([AuthRoutes, MainRoutes], config.basename);
}
