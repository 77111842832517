import { createSlice } from '@reduxjs/toolkit'

// project imports
import config from '../config';

const layoutSlice = createSlice({
    name: 'layout',
    initialState: {
        isOpen: [],
        fontFamily: config.fontFamily,
        borderRadius: config.borderRadius,
        opened: true,
        itemId: null,
        page: null
    },
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setMenu: (state, action) => {
            state.isOpen = [action.payload];
        },
        setMenuItem: (state, action) => {
            state.itemId = action.payload;
        },
        setMenuOpened: (state, action) => {
            state.opened = action.payload;
        },
    }
})

export const {
    setPage,
    setMenu,
    setMenuItem,
    setMenuOpened
} = layoutSlice.actions

export default layoutSlice.reducer


