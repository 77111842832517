import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "../reducers/Login";
import layoutSlice from "../reducers/Layout";
import filterSlice from "../reducers/Filter";
import { thunk } from "redux-thunk";

const store = configureStore({
  reducer: {
    Login: loginSlice,
    Layout: layoutSlice,
    Filter: filterSlice,
  },
  middleware: [thunk],
});

export default store;
