// third party
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// project imports
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import ReactDOM from "react-dom/client";

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
);

serviceWorker.register();
