import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../axios/Login";
import axiosInstanceLogout from "../axios/Logout";

const loginSlice = createSlice({
  name: "login",
  initialState: {
    token: localStorage.getItem("access_token_povat") || null,
    isAuthenticated: !!localStorage.getItem("access_token_povat"),
    passwordReset: false,
    passwordConfirmation: false,
    newRegister: false,
    is_antenado: localStorage.getItem("is_antenado") ?? true,
    profile: JSON.parse(localStorage.getItem("profile")) ?? [],
    isForbidden: false,
  },
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setLogin: (state, action) => {
      const _token = action.payload.token;
      const acess_token = _token.access_token;
      const refresh_token = _token.refresh_token;
      const expires_in = _token.expires_in;
      const _is_antenado = _token.is_antenado;
      state.isAuthenticated = true;
      state.token = acess_token;
      localStorage.setItem("access_token_povat", acess_token);
      localStorage.setItem("refresh_token_povat", refresh_token);
      localStorage.setItem(
        "expires_in_povat",
        Math.ceil(Date.now() + expires_in * 1000)
      );
      localStorage.setItem("is_antenado", _is_antenado);
    },
    setLogout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem("access_token_povat");
      localStorage.removeItem("refresh_token_povat");
      localStorage.removeItem("expires_in_povat");
      localStorage.removeItem("is_antenado");
      localStorage.removeItem("profile");
      localStorage.removeItem("userEmailLogin");
    },
    setPasswordReset: (state, action) => {
      state.passwordReset = action.payload;
    },
    setPasswordConfirmation: (state, action) => {
      state.passwordConfirmation = action.payload;
    },
    setNewRegister: (state, action) => {
      state.newRegister = action.payload;
    },
    setIsAntenado: (state, action) => {
      state.is_antenado = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
      localStorage.setItem("profile", JSON.stringify(action.payload));
    },
    setIsForbiden: (state, action) => {
      state.isForbidden = action.payload;
    },
  },
});

export const {
  setIsAuthenticated,
  setPasswordReset,
  setPasswordConfirmation,
  setNewRegister,
  setIsAntenado,
  setLogin,
  setLogout,
  setProfile,
  setIsForbiden,
} = loginSlice.actions;
export const validateToken = () => async (dispatch, getState) => {
  const token = getState().Login.token;

  if (token) {
    try {
      const response = await axiosInstance.get(`/api/user-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.is_active) {
        dispatch(validateProfile());
      } else {
        dispatch(revokeTokenAndLogout());
      }
    } catch (error) {
      dispatch(revokeTokenAndLogout());
    }
  } else {
    dispatch(revokeTokenAndLogout());
  }
};

export const validateProfile = () => async (dispatch, getState) => {
  const token = getState().Login.token;
  if (token) {
    try {
      const response = await axiosInstance.get(`/api/user-data/profile/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const profile = response.data?.profile;
        const _forbidden =
          profile.some((data) =>
            ["Interessado", "Tester"].includes(data.name)
          ) ?? false;
        dispatch(setIsForbiden(_forbidden));
        dispatch(setProfile(profile));
      } else {
        dispatch(revokeTokenAndLogout());
      }
    } catch (error) {
      dispatch(revokeTokenAndLogout());
    }
  } else {
    dispatch(revokeTokenAndLogout());
  }
};

// Função para revogar o token e fazer o logout
export const revokeTokenAndLogout = () => async (dispatch, getState) => {
  const token = getState().Login.token;

  if (token) {
    try {
      await axiosInstanceLogout.post(
        `/o/revoke_token/`,
        {
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          token: token,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Failed to revoke token:", error);
    }
  }

  // Desconectar o usuário localmente
  dispatch(setLogout());
};

export default loginSlice.reducer;
